import { useState, useEffect } from "react";

const services = [
  {
    title: "Construcción de Edificios Residenciales",
    description: "Creamos espacios modernos y funcionales.",
    images: [
      `${process.env.PUBLIC_URL}/ServicioA1.webp`,
      `${process.env.PUBLIC_URL}/ServicioA2.webp`,
      `${process.env.PUBLIC_URL}/ServicioA3.webp`,
    ],
    interval: 5500,
  },
  {
    title: "Reformas Integrales",
    description: "Renueva tu espacio con nuestros expertos.",
    images: [
      `${process.env.PUBLIC_URL}/ServicioB1.webp`,
      `${process.env.PUBLIC_URL}/ServicioB2.webp`,
      `${process.env.PUBLIC_URL}/ServicioB3.webp`,
    ],
    interval: 4500,
  },
  {
    title: "Mantenimiento y Reparación",
    description: "Mantenemos tus edificios en óptimas condiciones.",
    images: [
      `${process.env.PUBLIC_URL}/ServicioC1.webp`,
      `${process.env.PUBLIC_URL}/ServicioC2.webp`,
      `${process.env.PUBLIC_URL}/ServicioC3.webp`,
    ],
    interval: 3500,
  },
];

const Services = () => {
  const [imageIndices, setImageIndices] = useState(Array(services.length).fill(0));

  useEffect(() => {
    const intervals = services.map((service, i) =>
      setInterval(() => {
        setImageIndices((prev) =>
          prev.map((index, j) => (j === i ? (index + 1) % service.images.length : index))
        );
      }, service.interval + Math.random() * 2000) // Randomized interval variation
    );

    return () => intervals.forEach((interval) => clearInterval(interval));
  }, []);

  return (
    <div className="py-16 bg-gray-100" id="services">
      <h2 className="text-4xl font-bold text-center text-primary mb-12">Nuestros Servicios</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl mx-auto px-4">
        {services.map((service, index) => (
          <div
            key={index}
            className="relative overflow-hidden rounded-lg shadow-lg transition-shadow duration-300 hover:shadow-2xl"
          >
            <div className="relative w-full h-64 overflow-hidden">
              <img
                src={service.images[imageIndices[index]]}
                alt={service.title}
                className="absolute inset-0 w-full h-full object-cover transition-transform duration-1000 ease-in-out"
              />
            </div>
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white">
              <h3 className="text-2xl font-semibold">{service.title}</h3>
              <p className="mt-2 text-center px-4">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
