import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css"; // Asegúrate de importar los estilos de Leaflet

// Definimos un icono personalizado
const customIcon = new L.Icon({
  iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [25, 41], // Tamaño del icono
  iconAnchor: [12, 41], // Punto de anclaje del icono
  popupAnchor: [1, -34], // Punto de anclaje del popup relativo al icono
});

const Map = () => {
  const position = [-34.617547052992485, -58.36560008665284]; // Alicia Moreau de Justo 1150, Buenos Aires

  return (
    <div className="my-16" id="map">
      <h2 className="text-4xl font-bold text-center text-primary mb-8">Ubicación</h2>
      <div className="relative w-full h-96 overflow-hidden rounded-lg shadow-lg">
        <MapContainer 
          center={position} 
          zoom={15} 
          className="w-full h-full"
          style={{ zIndex: 1 }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position} icon={customIcon}>
            <Popup>
              <strong>Av. Alicia Moreau de Justo 1150</strong>
              <br /> C1107 Cdad. Autónoma de Buenos Aires
              <br /> ¡Visítanos!
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
};

export default Map;
