import { useState, useEffect } from "react";
import { Link } from "react-scroll";

const heroImages = [
  `${process.env.PUBLIC_URL}/hero.webp`,
  `${process.env.PUBLIC_URL}/hero2.webp`,
  `${process.env.PUBLIC_URL}/hero3.webp`,
];

const Hero = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const getRandomInterval = () => Math.floor(Math.random() * (7000 - 3000 + 1)) + 3000;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % heroImages.length);
    }, getRandomInterval());

    return () => clearInterval(interval);
  }, [currentImage]);

  return (
    <div
      className="relative bg-cover bg-center h-screen transition-all duration-1000"
      style={{ backgroundImage: `url(${heroImages[currentImage]})` }}
    >
      <div className="bg-black bg-opacity-60 absolute inset-0"></div>
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white">
        <h1 className="text-5xl md:text-7xl font-extrabold mb-4 animate-fadeIn">
          Cooperativa de Trabajo <br /> Luchando por Más Limitada
        </h1>
        <p className="text-xl md:text-2xl mb-8">
          Construyendo el Futuro con Pasión y Compromiso
        </p>
        <Link to="contact" smooth duration={500}>
          <button className="px-8 py-3 bg-accent text-white font-semibold rounded-lg hover:bg-yellow-500 transition-all">
            Contáctanos
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
