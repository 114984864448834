import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Hero from "./Components/Hero";
import Services from "./Components/Services";
import Map from "./Components/Map";
import Contact from "./Components/Contact";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Login from "./Components/Login";
import Mails from "./Components/Mails";
import "leaflet/dist/leaflet.css";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  return token ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <div className="font-sans bg-gray-50 min-h-screen">
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero />
              <div className="container mx-auto px-6 sm:px-8 lg:px-12">
                <Services />
                <Map />
                <Contact />
              </div>
            </>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/mails"
          element={
            <ProtectedRoute>
              <Mails />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
