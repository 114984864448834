import { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" }); // Clear specific error
    setServerError(""); // Clear any server error when user changes input
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "Por favor, ingresa tu nombre.";
    if (!formData.email) {
      formErrors.email = "Por favor, ingresa tu correo.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Ingresa un correo válido.";
    }
    if (!formData.message) formErrors.message = "Por favor, ingresa tu mensaje.";
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setLoading(true); // Set loading state

    try {
      const response = await fetch("https://turnos.nistal.net/api/constructora/mails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitted(true);
        setFormData({ name: "", email: "", message: "" }); // Reset form
        setTimeout(() => setSubmitted(false), 3000); // Reset success message
      } else {
        const errorData = await response.json();
        setServerError(errorData.message || "Error al enviar el formulario.");
      }
    } catch (error) {
      console.error("Error de red:", error);
      setServerError("Error de red. Inténtalo nuevamente.");
    } finally {
      setLoading(false); // Stop loading state
    }
  };

  return (
    <div className="py-16 bg-gray-100" id="contact">
      <h2 className="text-4xl font-bold text-center text-primary mb-12">Contáctanos</h2>
      <form
        onSubmit={handleSubmit}
        className={`max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg space-y-4 transition-transform ${
          submitted ? "scale-105" : "scale-100"
        }`}
      >
        {serverError && <p className="text-red-500 text-center mb-4">{serverError}</p>}

        {/* Nombre */}
        <div>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Tu nombre"
            className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-accent ${
              errors.name ? "border-red-500" : ""
            }`}
          />
          {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
        </div>

        {/* Email */}
        <div>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Tu correo"
            className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-accent ${
              errors.email ? "border-red-500" : ""
            }`}
          />
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
        </div>

        {/* Mensaje */}
        <div>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Tu mensaje"
            rows="4"
            className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-accent ${
              errors.message ? "border-red-500" : ""
            }`}
          />
          {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
        </div>

        {/* Botón de envío */}
        <button
          type="submit"
          className={`w-full py-2 rounded-lg transition-all ${
            submitted ? "bg-green-500" : "bg-accent"
          } text-white flex items-center justify-center`}
          disabled={loading} // Disable button while loading
        >
          {loading ? (
            <svg
              className="animate-spin h-5 w-5 mr-2 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8z"
              ></path>
            </svg>
          ) : submitted ? (
            "Mensaje Enviado ✔️"
          ) : (
            "Enviar"
          )}
        </button>
      </form>
    </div>
  );
};

export default Contact;
