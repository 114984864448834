import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns"; // For friendly timestamps
import { FiMail, FiSearch } from "react-icons/fi"; // Icons

const Mails = () => {
  const [mails, setMails] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const [search, setSearch] = useState(""); // Search input
  const navigate = useNavigate();

  // Fetch emails from backend
  useEffect(() => {
    const fetchMails = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        const response = await fetch("https://turnos.nistal.net/api/constructora/mails", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          const data = await response.json();
          setMails(data);
        }
      } catch (error) {
        console.error(error);
        setError("Error al cargar los mails");
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchMails();
  }, [navigate]);

  const handleSearchChange = (e) => setSearch(e.target.value.toLowerCase());

  const filteredMails = mails.filter(
    (mail) =>
      mail.email.toLowerCase().includes(search) ||
      mail.message.toLowerCase().includes(search)
  );

  // Loading screen
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-12 px-6 sm:px-8">
      <h2 className="text-4xl font-bold text-center text-gradient bg-gradient-to-r from-blue-500 to-indigo-600 bg-clip-text text-transparent mb-12">
        Mails Recibidos
      </h2>

      {error && <p className="text-red-500 text-center mb-4">{error}</p>}

      <div className="flex justify-center items-center mb-8 space-x-4">
        <FiSearch className="text-blue-500 text-2xl" />
        <input
          type="text"
          placeholder="Buscar por correo o mensaje..."
          value={search}
          onChange={handleSearchChange}
          className="w-full max-w-md px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {filteredMails.length === 0 ? (
        <div className="flex flex-col items-center">
          <FiMail className="text-gray-400 text-8xl mb-4" />
          <p className="text-gray-500 text-center">No hay mails disponibles.</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredMails.map((mail) => (
            <div
              key={mail.id}
              className="bg-white p-6 rounded-lg shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl"
            >
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-xl font-semibold text-blue-600">
                  {mail.email}
                </h3>
                <span className="text-sm bg-blue-100 text-blue-700 px-2 py-1 rounded-full">
                  {formatDistanceToNow(new Date(mail.created_at), {
                    addSuffix: true,
                  })}
                </span>
              </div>
              <p className="text-gray-700 mb-4">{mail.message}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Mails;
