import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";

const Footer = () => (
  <footer className="bg-primary text-white py-8">
    <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
      <div>
        <h3 className="text-lg font-semibold">Contáctanos</h3>
        <p className="mt-2 flex items-center">
          <FaPhone className="mr-2" /> +54 11 7143-6828
        </p>
        <p className="mt-2 flex items-center">
          <FaEnvelope className="mr-2" /> <a href="mailto:administracion@luchandopormas.com">administracion@luchandopormas.com</a>
        </p>
        <p className="mt-2 flex items-center">
          <FaMapMarkerAlt className="mr-2" />
          Av. Alicia Moreau de Justo 1150, C1107 Cdad. Autónoma de Buenos Aires
        </p>
      </div>

      <div>
        <h3 className="text-lg font-semibold">Contacto del Creador</h3>
        <p className="mt-2 flex items-center">
          <FaEnvelope className="mr-2" /> <a href="mailto:tomas@nistal.net">tomas@nistal.net</a>
        </p>
      </div>

      <div className="flex justify-center md:justify-end space-x-6">
        <FaFacebook className="text-2xl cursor-pointer hover:text-accent transition-colors duration-300" />
        <FaInstagram className="text-2xl cursor-pointer hover:text-accent transition-colors duration-300" />
        <FaLinkedin className="text-2xl cursor-pointer hover:text-accent transition-colors duration-300" />
        <FaTwitter className="text-2xl cursor-pointer hover:text-accent transition-colors duration-300" />
      </div>
    </div>

    <div className="text-center mt-8">
      <p className="text-sm">
        © 2024 Cooperativa de Trabajo Luchando por Más Limitada. Todos los derechos reservados.
      </p>
    </div>
  </footer>
);

export default Footer;
