import { useState, useEffect } from "react";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"; // For scrolling
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom"; // For routing
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // To detect the current path

  // Handle scroll detection for background change
  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleToggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleLogoClick = () => navigate("/"); // Redirect to "/"

  const isHomePage = location.pathname === "/"; // Check if on the homepage

  // Smoothly scroll to the hero section
  const scrollToHero = () => {
    if (isHomePage) {
      scroll.scrollToTop({ duration: 500, smooth: true });
    } else {
      navigate("/", { state: { scrollToHero: true } }); // Redirect and scroll
    }
  };

  // Detect if we need to scroll to Hero when redirected to "/"
  useEffect(() => {
    if (location.state?.scrollToHero) {
      scroll.scrollToTop({ duration: 500, smooth: true });
    }
  }, [location]);

  return (
    <nav
      className={`fixed top-0 left-0 w-full transition-all duration-300 z-50 ${
        isScrolled ? "bg-gray-800 shadow-md" : "bg-gray-900"
      }`}
    >
      <div className="max-w-7xl mx-auto flex justify-between items-center p-4">
        <img
          src={`${process.env.PUBLIC_URL}/logo2.webp`}
          alt="Logo"
          className="w-20 h-20 cursor-pointer"
          onClick={handleLogoClick}
        />

        {/* Desktop Menu */}
        {isHomePage && (
          <div className="hidden md:flex space-x-6">
            <button
              onClick={scrollToHero}
              className="cursor-pointer text-white hover:text-accent focus:outline-none"
            >
              Inicio
            </button>
            <ScrollLink
              to="services"
              smooth
              duration={500}
              className="cursor-pointer text-white hover:text-accent"
            >
              Servicios
            </ScrollLink>
            <ScrollLink
              to="contact"
              smooth
              duration={500}
              className="cursor-pointer text-white hover:text-accent"
            >
              Contacto
            </ScrollLink>
            <RouterLink
              to="/login"
              className="cursor-pointer text-white hover:text-accent"
            >
              Iniciar sesión
            </RouterLink>
          </div>
        )}

        {/* Mobile Menu Toggle */}
        <div className="md:hidden">
          {isMenuOpen ? (
            <FaTimes
              className="text-2xl cursor-pointer"
              onClick={handleToggleMenu}
            />
          ) : (
            <FaBars
              className="text-2xl cursor-pointer"
              onClick={handleToggleMenu}
            />
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && isHomePage && (
        <div className="md:hidden bg-gray-800 p-4 space-y-4">
          <button
            onClick={() => {
              scrollToHero();
              handleToggleMenu();
            }}
            className="block text-white cursor-pointer hover:text-accent focus:outline-none"
          >
            Inicio
          </button>
          <ScrollLink
            to="services"
            smooth
            duration={500}
            className="block text-white cursor-pointer hover:text-accent"
            onClick={handleToggleMenu}
          >
            Servicios
          </ScrollLink>
          <ScrollLink
            to="contact"
            smooth
            duration={500}
            className="block text-white cursor-pointer hover:text-accent"
            onClick={handleToggleMenu}
          >
            Contacto
          </ScrollLink>
          <RouterLink
            to="/login"
            className="block text-white cursor-pointer hover:text-accent"
            onClick={handleToggleMenu}
          >
            Iniciar sesión
          </RouterLink>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
